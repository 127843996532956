import React from 'react';
import styled from 'styled-components';
import MsgIcon from 'imgs/common/icon.msg.png';
import TelIcon from 'imgs/common/icon.tel.png';

const Wrapper = styled.div`
  position: fixed;
  right: 12px;
  bottom: 16%;
  display: flex;
  flex-direction: column;
  z-index: 996;
  .icon {
    border-radius: 50%;
    background-color: #fff;
    padding: 14px;
    width: 50px;
    height: 50px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05), 0 10px 20px 0 rgba(0, 0, 0, 0.12);
    :not(:last-child) {
      margin-bottom: 12px;
    }
    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
      }
    }
  }
`;

const Contact = ({ visible = true }) => {
  return visible ? (
    <Wrapper>
      <div className="icon">
        <a href="tel:400-666-7689">
          <img src={TelIcon} alt="电话图标" />
        </a>
      </div>
      <div className="icon">
        <a href="https://intelcc-user.icsoc.net?channelKey=8710ef4410817d56e0e314075be124b2&init=1">
          <img src={MsgIcon} alt="短信图标" />
        </a>
      </div>
    </Wrapper>
  ) : null;
};

export default Contact;
