import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Nav from './__Nav';
import Footer from './__Footer';
import GlobalStyle from './__Global.style';
import Fontsize from './__Fontsize';
import MTA from 'RootCommon/MTA';
import Contact from 'RootCommon/MContact';

export default function Container({ children }) {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      delay: 300
    });
  }, []);

  return (
    <>
      <GlobalStyle />
      <Fontsize />
      <Nav />
      <Contact />
      <div style={{ marginTop: '1.04rem', overflow: 'hidden', width: '100%' }}>{children}</div>
      <Footer />
      {process.env.NODE_ENV == 'production' && <MTA sid="500692331" />}
    </>
  );
}
